<template>
  <div class="">
    <Breadcrumbs title="Manage User" main="Users"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Details</h5>
            </div>
            <form @submit.prevent="submitCreateForm" class="card-body">

              <b-row>
                <b-col cols="12" v-for="(error, index) in errorBag2" :key="index"  class="bg-danger p-2 m-b-5">
                  {{ error[0] }}
                </b-col>
              </b-row>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Username <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model.lazy="user.username" class="form-control" :state="hasError('username')"></b-form-input>
                  <b-form-invalid-feedback :state="hasError('username')">{{getError('username')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>First Name <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model.lazy="user.first_name" class="form-control" :state="hasError('first_name')"></b-form-input>
                  <b-form-invalid-feedback :state="hasError('first_name')">{{getError('first_name')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Last Name <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model.lazy="user.last_name" class="form-control" :state="hasError('last_name')"></b-form-input>
                  <b-form-invalid-feedback :state="hasError('last_name')">{{getError('last_name')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>NIC <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-input @change="nicChanged" v-model="user.nic" class="form-control" minlength="10" maxlength="12" :state="hasError('nic')" required></b-form-input>
                  <b-form-invalid-feedback :state="hasError('nic')">{{getError('nic')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Mobile <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model="user.phone_mobile" class="form-control" minlength="9" maxlength="9" placeholder="Last 9 digits" :state="hasError('phone_mobile')" required></b-form-input>
                  <b-form-invalid-feedback :state="hasError('phone_mobile')">{{getError('phone_mobile')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Phone (Home)</strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model="user.phone_home" class="form-control" minlength="9" maxlength="10" :state="hasError('phone_home')"></b-form-input>
                  <b-form-invalid-feedback :state="hasError('phone_home')">{{getError('phone_home')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Email Address</strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model="user.email" :state="hasError('email')" class="form-control"></b-form-input>
                  <b-form-invalid-feedback :state="hasError('email')">{{getError('email')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Address Line 1</strong></div>
                <div class="col-sm-9">
                  <b-form-textarea v-model="user.address_line_1" :state="hasError('address_line_1')" class="form-control"></b-form-textarea>
                  <b-form-invalid-feedback :state="hasError('address_line_1')">{{getError('address_line_1')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Address Line 2</strong></div>
                <div class="col-sm-9">
                  <b-form-textarea v-model="user.address_line_2" :state="hasError('address_line_2')" class="form-control"></b-form-textarea>
                  <b-form-invalid-feedback :state="hasError('address_line_2')">{{getError('address_line_2')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Status <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-select v-model="user.status" :options="statusOptions" :state="hasError('status')" value-field="id" text-field="text" class="border">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Select an institute --</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback :state="hasError('status')">{{getError('status')}}</b-form-invalid-feedback>
                </div>
              </div>





              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Institutes <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <div v-if="institutes" class="animate-chk">
                    <div class="row">
                      <div class="checkbox-animated col-12 col-md-6 col-lg-4" v-for="(institute, index) in institutes" :key="index">
                        <label class="d-block">
                          <input class="checkbox_animated" name="institutes" type="checkbox"
                                 v-model="user_institutes"
                                 :checked="user_institutes.includes(institute.id)"
                                 :value="institute.id"
                          > {{institute.text}}
                        </label>
                      </div>
                    </div>
                  </div>
                  <b-form-invalid-feedback :state="hasError('institutes')">{{getError('institutes')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right">
                  <strong>Roles <span class="font-danger">*</span></strong>
                </div>
                <div class="col-sm-9">
                  <div v-if="roles.length" class="animate-chk">
                    <div class="row">
                      <div class="checkbox-animated col-12 col-md-6 col-lg-4" v-for="(role, index) in roles" :key="index">
                        <label class="d-block">
                          <input class="checkbox_animated" name="role" type="checkbox"
                                 v-model="user_roles"
                                 :checked="user_roles.includes(role.id)"
                                 :value="role.id"
                          > <span class="text-capitalize">{{role.text}}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <b-form-invalid-feedback :state="hasError('roles')">{{getError('roles')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right">
                  <strong>Subjects <span class="font-danger">*</span></strong>
                </div>
                <div class="col-sm-9">
                  <div v-if="subjects.length" class="animate-chk">
                    <div class="row">
                      <div class="checkbox-animated col-12 col-md-6 col-lg-4" v-for="(subject, index) in subjects" :key="index">
                        <label class="d-block">
                          <input class="checkbox_animated" name="role" type="checkbox"
                                 v-model="user_subjects"
                                 :checked="user_subjects.includes(subject.id)"
                                 :value="subject.id"
                          > <span class="text-capitalize">{{subject.text}}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <b-form-invalid-feedback :state="hasError('subjects')">{{getError('subjects')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right">
                  <strong>Password</strong>
                </div>
                <div class="col-sm-9">
                  <div class="animate-chk m-t-10">
                    <div class="checkbox-animated">
                      <label class="d-block">
                        <input class="checkbox_animated" name="role" type="checkbox" v-model="update_password" value="1">
                        <span class="text-capitalize">Change Password</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row" v-show="update_password">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>New Password</strong></div>
                <div class="col-sm-9">
                  <b-form-input type="password" v-model="password" :state="hasError('password')" class="form-control"></b-form-input>
                  <b-form-invalid-feedback :state="hasError('password')">{{getError('password')}}</b-form-invalid-feedback>
                </div>
              </div>
              <div class="form-group row" v-show="update_password">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Confirm Password</strong></div>
                <div class="col-sm-9">
                  <b-form-input type="password" v-model="password_confirmation" :state="hasError('password')" class="form-control"></b-form-input>
                </div>
              </div>


              <div class="card-footer text-center">
                <b-button class="btn-square m-r-10 text-uppercase" variant="primary" type="submit">Update</b-button>
                <b-button class="btn-square text-uppercase" variant="light" @click="resetFormData">Reset</b-button>
              </div>

            </form>

          </div>

          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Classes</h5>
            </div>
            <div class="card-body">
              <b-row>
                <b-col cols="12" lg="3">
                  <b-form-group label="Institute">
                    <b-form-select @change="fetchAssignClasses" v-model="formAssignClass.institute_id" :options="user.institutes" value-field="id" text-field="name" class="border">
                      <template #first>
                        <b-form-select-option :value="null" disabled>-- Select an institute --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                  <b-form-group label="Subject">
                    <b-form-select @change="fetchAssignClasses" v-model="formAssignClass.subject_id" :options="user.subjects" value-field="id" text-field="name" class="border">
                      <template #first>
                        <b-form-select-option :value="null" disabled>-- Select a subject --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="5">
                  <b-form-group label="Class">
                    <b-form-select :disabled="!assign_classes.length" v-model="formAssignClass.class_detail_id" :options="assign_classes" value-field="id" text-field="text" class="border">
                      <template #first>
                        <b-form-select-option :value="null" disabled>-- Select a class --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="1">
                  <b-button @click="submitAssignClass" class="btn-square m-t-30 text-uppercase" variant="primary" type="submit">Assign</b-button>
                </b-col>
              </b-row>





              <div class="table-responsive datatable-vue">
                <b-table
                    ref="table"
                    show-empty
                    selected-variant="success"
                    :items="tableRecords"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="1"
                    :per-page="perPage"
                    @filtered="onFiltered"
                    @row-selected="rowSelected"
                >
                  <template #cell(action)="row" class="text-right">
                    <b-button @click="showRemoveStudentClass(row.index, row.item, $event.target)" class="btn-square" variant="danger" title="Remove class from the student">Remove</b-button>
                  </template>
                </b-table>
              </div>


              <b-row >
                <b-col cols="4">
                  Records: <strong>{{ totalRows }}</strong>
                </b-col>
                <b-col cols="8" class="my-1" v-if="totalRows">
                  <b-pagination
                      align="right"
                      class="my-0"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      pills
                  ></b-pagination>
                </b-col>
              </b-row>

            </div>
          </div>

        </div>
      </div>
    </div>


    <!-- Begin Delete Modal -->
    <b-modal id="remove_class_modal" title="Delete" cancel-variant="default" ok-variant="danger" ok-title="Confirm" @hide="clearRemoveClassModals" @ok="submitRemoveClassModal">
      <h6 class="m-b-10">Please confirm removal of following class from selected student:</h6>
      <div class="row">
        <div class="col-md-2"><strong>Class</strong></div>
        <div class="col-md-10"><div class="font-danger" v-text="removeClassModal.name"></div></div>
      </div>
    </b-modal>
    <!-- End Delete Modal -->


  </div>
</template>

<script>
import CRUD_API from '@/services/crud'
import API from '@/services/api'
import { mapGetters } from 'vuex'
export default {
  name: "users-edit",
  created() {
    this.$store.dispatch('select/fetchRecords', { id: 'roles' })
    this.$store.dispatch('select/fetchRecords', { id: 'institutes' })
    this.$store.dispatch('select/fetchRecords', { id: 'subjects' })
  },
  mounted() { this.resetFormData(); },
  computed: mapGetters({
    institutes: 'select/institutes',
    roles: 'select/roles',
    subjects: 'select/subjects',
  }),
  watch: {
    currentPage() {
      this.fetchData()
    },
    perPage() {
      this.currentPage = 1
      this.fetchData()
    },
    filter() {
      this.currentPage = 1
      this.fetchData()
    }
  },
  data() {
    return {
      errorBag2: [],
      errorBag: [],
      user_institutes: [],
      user_roles: [],
      user_subjects: [],
      user_classes: [],
      class_details: [],
      update_password: false,
      password_confirmation: '',
      password: '',
      user: {
        id: null,
        nic: '',
        email: '',
        dob: null,
        gender: 0,
        username: '',
        first_name: '',
        last_name: '',
        phone_home: null,
        phone_mobile: '',
        address_line_1: null,
        address_line_2: null,
        status: 1,
        institutes: [],
        roles: [],
        subjects: [],
        classDetails: [],
      },
      statusOptions: [
        { id: 0, text: 'Active' },
        { id: 1, text: 'Pending' },
        { id: 2, text: 'Suspended' },
      ],



      filter: '',
      totalRows: 0,
      perPage: 5,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      tableFields: [
        // { key: 'id', label: 'ID', sortable: false },
        { key: 'institute.name', label: 'Institute', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'class_fee', label: 'Class Fee', sortable: true, class: 'text-right' },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],
      selected: null,
      removeClassModal: {
        id: null,
        class_detail_id: null,
        student_name: '',
        class_name: ''
      },

      tableRecords: [],
      assign_classes: [],
      formAssignClass: {
        institute_id: null,
        subject_id: null,
        class_detail_id: null,
      }
    }
  },
  methods: {
    clearErrors(){ this.errorBag = []; },
    hasError(ifn){ if (this.errorBag) { return !this.errorBag.hasOwnProperty(ifn); } return null; },
    getError(ifn){ if(this.errorBag) { if (!this.hasError(ifn)) { return this.errorBag[ifn][0]; } } return null; },
    onFiltered(filteredItems) {
      this.currentPage = 1
      this.items.count = filteredItems.length
    },
    rowSelected(item) {
      this.selected = item
    },
    resetFormData() {
      this.clearErrors()
      CRUD_API.show('/api/backend/pages/users', this.$route.params.userId)
          .then((response) => {
            this.user = response.data.data
            this.user_institutes = this.user.institutes.map((institute) => { return institute.id })
            this.user_roles = this.user.roles.map((role) => { return role.id })
            this.user_subjects= this.user.subjects.map((subj) => { return subj.id })
            this.fetchClasses()
          }).catch((error) => {
            this.user_institutes = []
            this.user_roles = []
            this.user_subjects = []
            this.user_classes = []
            this.errorBag = error.response.data.data
            this.$toasted.error(error.message)
          })
    },
    async fetchClasses(){
      await CRUD_API.index('/api/backend/pages/classes', {
        params: {
          student_id: this.user.id,
          status: 0
        }
      }).then((result) => {
        this.totalRows = result.data.data.total
        this.tableRecords = result.data.data.data
      }).catch((error) => {
        this.tableRecords = []
        console.log(error)
      })
    },
    submitCreateForm() {
      this.clearErrors()
      this.nicChanged()
      try {
        let FormData = this.user
        FormData.update_password = this.update_password
        FormData.password = this.password
        FormData.password_confirmation = this.password_confirmation
        FormData.roles = this.user_roles
        FormData.institutes = this.user_institutes
        FormData.subjects = this.user_subjects
        CRUD_API.update('/api/backend/pages/users', this.$route.params.userId, FormData)
          .then((resp) => {
            if (resp.data.success) {
              this.errorBag = []
              this.errorBag2 = []
              this.$toasted.success("User account updated successfully.")
            } else{
              this.errorBag = resp.data.data
              this.errorBag2 = resp.data.data
              console.log(resp.data.data)
              this.$toasted.error(resp.data.message)
            }
          }).catch((error) => {
            console.log(error)
            this.$toasted.error(error.message)
          }).finally(() => {
            this.resetFormData()
          })
      } catch (e) {
        console.log(e)
      }
    },
    nicChanged() {
      let dayText = 0;
      let year = "";
      let month = "";
      let day = "";
      if (this.user.nic.length !== 10 && this.user.nic.length !== 12) {
        this.$toasted.show('Invalid NIC NO', {
          fullWidth: true,
          fitToScreen: true,
          position: 'top-center',
          type: 'error',
        })
      }
          //else if (this.form.credentials.nic.length == 10 && !$.isNumeric(this.form.credentials.nic.substr(0, 9))) {
          //$("#error").html("Invalid NIC NO");
      //}
      else {
        // Year
        if (this.user.nic.length === 10) {
          year = "19" + this.user.nic.substr(0, 2);
          dayText = parseInt(this.user.nic.substr(2, 3));
        } else {
          year = this.user.nic.substr(0, 4);
          dayText = parseInt(this.user.nic.substr(4, 3));
        }

        // Gender
        if (dayText > 500) {
          this.user.gender = 1;
          dayText = dayText - 500;
        } else {
          this.user.gender = 0;
        }

        // Day Digit Validation
        if (dayText < 1 && dayText > 366) {
          // Invalid NIC NO
          this.user.dob = null
          this.$toasted.show('Invalid NIC NO', {
            fullWidth: true,
            fitToScreen: true,
            position: 'top-center',
            type: 'error',
          })
        } else {

          //Month
          if (dayText > 335) {
            day = dayText - 335;
            month = "12";
          }
          else if (dayText > 305) {
            day = dayText - 305;
            month = "11";
          }
          else if (dayText > 274) {
            day = dayText - 274;
            month = "10";
          }
          else if (dayText > 244) {
            day = dayText - 244;
            month = "09";
          }
          else if (dayText > 213) {
            day = dayText - 213;
            month = "08";
          }
          else if (dayText > 182) {
            day = dayText - 182;
            month = "07";
          }
          else if (dayText > 152) {
            day = dayText - 152;
            month = "06";
          }
          else if (dayText > 121) {
            day = dayText - 121;
            month = "05";
          }
          else if (dayText > 91) {
            day = dayText - 91;
            month = "04";
          }
          else if (dayText > 60) {
            day = dayText - 60;
            month = "03";
          }
          else if (dayText < 32) {
            month = "01";
            day = dayText;
          }
          else if (dayText > 31) {
            day = dayText - 31;
            month = "02";
          }
          this.user.dob = year + '/' + month + '/' + day
        }
      }
    },


    async fetchAssignClasses() {
      this.formAssignClass.class_detail_id = null
      this.assign_classes = []
      if(this.formAssignClass.institute_id != null && this.formAssignClass.subject_id != null){
        await API.select('classes', {
          institute_id: this.formAssignClass.institute_id,
          subject_id: this.formAssignClass.subject_id,
          select_mode: 'available'
        }).then((result) => {
          this.assign_classes = result.data.data
        }).catch((error) => {
          this.assign_classes = []
          console.log(error)
        })
      }
    },
    async submitAssignClass() {
      if(this.formAssignClass.class_detail_id===null) {
        this.$toasted.error("Class not selected: " +this.formAssignClass.class_detail_id)
      } else {
        CRUD_API.create('/api/backend/pages/user-assign-class', {
          class_detail_id: this.formAssignClass.class_detail_id,
          institute_id: this.formAssignClass.institute_id,
          student_id: this.user.id
        }).then((response) => {
          this.formAssignClass.class_detail_id = null
          this.$toasted.success(response.data.message)
        }).catch((errors) => {
          console.log(errors)
          this.$toasted.success(errors.data.message)
        }).finally(() => {
          this.resetFormData()
        })
      }
    },
    clearRemoveClassModals() {
      this.removeClassModal.id = null
      this.removeClassModal.class_detail_id = null
      this.removeClassModal.student_name = null
      this.removeClassModal.class_name = null
    },
    showRemoveStudentClass(index, item, button){
      this.removeClassModal.class_detail_id = item.id
      this.removeClassModal.class_name = item.name
      this.$root.$emit('bv::show::modal', 'remove_class_modal', button)
    },
    async submitRemoveClassModal(){
      await API.remove_class_from_student({
        class_detail_id: this.removeClassModal.class_detail_id,
        student_id: this.user.id,
      }).then((resp) => {
        if(resp.data.success) {
          this.$toasted.success(resp.data.message)
        } else {
          this.$toasted.error(resp.data.message)
        }
      }).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while removing class from the student")
      }).finally(() => {
        this.resetFormData()
      })
    },
  }
}
</script>

<style scoped>

</style>
